<template>
  <div class="tab-pane active" id="live-stats">
    <div class="card-body">
      <div class="table-responsive">
        <div class="loading min-h-300" v-if="loading">
          <LoadingAnim />
        </div>

        <data-table
          v-else
          :data="rooms"
          :pages="pages"
          :columns="[
            {
              key: 'creation_date',
              filters: ['date|DD/MM/YYYY'],
              label: 'created_at',
            },
            'protocol',
            {
              key: 'channel',
              class: 'badge badge-primary text-white',
            },
            {
              key: 'open_date',
              filters: ['date|HH:mm:ss'],
              label: 'start_hour',
            },
            {
              key: 'close_date',
              filters: ['date|HH:mm:ss'],
              label: 'end_hour',
            },
            'name',
            'phone',
            {
              key: 'agent_name',
              label: 'agent',
            },
            'tabulation',
            'evaluation',
          ]"
          @change-page="prefetch"
        >
          <template #actions="item">
            <a href="javascript:void(0)" @click="showMessages(item)">
              <i class="fas fa-comments"></i>
            </a>
          </template>
        </data-table>
      </div>
    </div>
    <modal ref="messages-modal" title="Conversa" no-footer>
      <chat-conversation :room="currentRoom" />
    </modal>
  </div>
</template>

<script>
// @ is an alias to /src
import moment from 'moment';
import Tab from '@/directives/Tab';
import UsageService from '@/services/usage.service';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import DataTable from '@/components/rebranding/DataTable.vue';
import Modal from '@/components/Modal.vue';
import ChatConversation from '@/components/chat/ChatConversation.vue';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'LiveReport',
  directives: {
    Tab,
  },
  components: {
    DataTable,
    LoadingAnim,
    Modal,
    ChatConversation,
  },
  props: {
    filters: {
      type: Object,
    },
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
  },
  data() {
    return {
      loading: true,
      rooms: [],
      tempDownload: null,
      currentRoom: null,
      fetchTimeout: 0,
      fetched: true,
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('01/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      usage: [],
      pages: 0,
      form: {
        // category: 'sms',
        // date: {
        //   gte: moment().format('YYYY-MM-01'),
        //   lte: moment().format('YYYY-MM-DD'),
        // },
      },
    };
  },
  /* computed: {
    dateGte() {
      this.prefetch(1);
      return this.$parent.form.date.gte;
    },
    dateLte() {
      this.prefetch(1);
      return this.$parent.form.date.lte;
    },
  }, */
  watch: {
    dateGte: {
      handler(value) {
        console.log('reports:history', value);
        this.prefetch(1);
      },
      deep: true,
    },
    dateLte: {
      handler(value) {
        console.log('reports:history', value);
        this.prefetch(1);
      },
      deep: true,
    },
  },
  created() {
    this.fetch();
  },
  beforeDestroy() {
    if (this.tempDownload) URL.revokeObjectURL(this.tempDownload);
  },
  methods: {
    fetch(page = 1) {
      this.loading = true;
      this.$store
        .dispatch('fetchOmniRooms', {
          filter: {
            ...this.filters,
            createdAt: {
              gte: this.startDate,
              lt: this.endDate,
            },
          },
          page,
        })
        .then((response) => {
          this.rooms = response.data.data;
          this.pages = response.data.last_page;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showMessages(room) {
      this.fetchRoom(room.id);
    },
    fetchRoom(roomId) {
      this.currentRoom = null;
      this.$refs['messages-modal'].toggle();
      this.$store.dispatch('fetchOmniRoom', roomId).then(
        (room) => {
          this.currentRoom = room;
        },
        (error) => {
          this.content = error;
          console.log(JSON.stringify(error.response.data));
        },
      );
    },
    reload() {
      this.fetch(1);
    },
    prefetch(page) {
      clearTimeout(this.fetchTimeout);
      this.fetchTimeout = setTimeout(() => this.fetch(page), 10);
    },
    exportData(format) {
      this.loading = true;
      console.log(this.filters);
      console.log('FETCH OMNI');

      // this.filters.page = page - 1;
      this.fetched = false;

      // query=x&query=y&page=1

      const accounId = this.$store.state.account.id;
      const form = this.form;

      const params = [];

      if (form.id) {
        params.push(`id;eq;'${form.id}'`);
      } else {
        if (this.filters.channels.length > 0) {
          const channels = this.filters.channels.map((value) => value.toUpperCase());
          params.push(`channel_id;in;'${channels}'`);
        }

        if (this.filters.tabs.length > 0) {
          const tabs = this.filters.tabs.map((value) => value.toUpperCase());
          params.push(`tabs;in;'${tabs}'`);
        }

        if (this.filters.tags.length > 0) {
          const tags = this.filters.tags.map((value) => value.toUpperCase());
          params.push(`tag;in;'${tags}'`);
        }

        if (this.filters.protocol) {
          params.push(`protocol;eq;'${encodeURIComponent(this.filters.protocol)}'`);
        }

        if (this.startDate) {
          params.push(`creation_date;gte;'${new Date(this.startDate).toISOString()}'`);
        }

        if (this.endDate) {
          params.push(`creation_date;lte;'${new Date(this.endDate).toISOString()}'`);
        }

        params.push(`account_id;eq;'${accounId}'`);
      }

      const orderBy = 'orderBy=creation_date;desc';

      webApi.post(`/omni/reports/generate?query[]=${params.join('&query[]=')}&format=${format}&${orderBy}`, {
        from: this.filters.gte,
        to: this.filters.lte,
        format,
      }).then((response) => {
        console.log('Export');
        console.log(response);
        this.loading = false;
        this.$router.push('/live/reports/exports');
      }, (error) => {
        this.loading = false;
        this.content = error;
        this.$toast.show({
          title: 'Erro',
          content: 'Erro interno, por favor tente novamente mais tarde ou contact o suporte para ajuda.',
          type: 'error',
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;

  &::v-deep {
    table {
      text-align: center;

      th,
      td {
        padding: 0.625em;
        text-align: center;
      }

      th {
        font-size: 0.85em;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        font-weight: 500;
        vertical-align: bottom;
        color: #212529 !important;
      }

      td {
        font-weight: 300 !important;
      }

      thead,
      tbody {
        color: var(--foreground);
      }
    }
  }
}
</style>
